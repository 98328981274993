<template>
  <div class="CounselorDetails bg-white" style="height: 100%">
    <i-form :model="counselorForm" :label-width="72" :rules="ruleVale">
      <Row :gutter="8" class="px-1">
        <i-col span="18">
          <Row :gutter="8">
            <i-col span="8">
              <FormItem label="咨询帐号">
                <i-input size="large" disabled v-model="counselorForm.counselorId"></i-input>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="姓名">
                <i-input size="large" v-model="counselorForm.counselorName"></i-input>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="性别">
                <RadioGroup v-model="counselorForm.gender" style="width: 100%; text-align: left;">
                  <Radio :label="1" style="padding-right:2rem;margin-left: 2rem;">男</Radio>
                  <Radio :label="0" style="padding-right:2rem">女</Radio>
                </RadioGroup>
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="8">
            <i-col span="8">
              <FormItem label="生日" style="text-align: left;">
                <DatePicker
                  size="large"
                  v-model="counselorForm.birthday"
                  type="date"
                  placeholder="选择日期"
                  format="yyyy-MM-dd"
                  style="width: 100%;"
                ></DatePicker>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="手机号">
                <i-input size="large" v-model="counselorForm.bindPhone" placeholder="勿使用生活手机号"></i-input>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="价格">
                <InputNumber
                  size="large"
                  :max="5000"
                  :min="100"
                  :step="50"
                  v-model="counselorForm.price"
                  style="width: 100%;"
                ></InputNumber>
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="8">
            <i-col span="8">
              <FormItem label="折扣">
                <InputNumber
                  size="large"
                  :max="1"
                  :min="0"
                  :step="0.05"
                  v-model="counselorForm.discount"
                  style="width: 100%;"
                ></InputNumber>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="资格">
                <Select v-model="counselorForm.technicalTitle" size="large">
                  <Option v-for="(tItem,tkey) in titleOption" :key="tkey" :value="tkey">{{tItem}}</Option>
                </Select>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="证书号">
                <i-input size="large" v-model="counselorForm.certificate"></i-input>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="经验">
                <i-input size="large" v-model="counselorForm.experience">
                  <span slot="append">小时</span>
                </i-input>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="方式">
                <i-select
                  v-model="counselorForm.way"
                  multiple
                  :max-tag-count="maxTags"
                  size="large"
                >
                  <Option v-for="(wItem,wkey) in wayOption" :key="wkey" :value="wkey">{{wItem}}</Option>
                </i-select>
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem label="擅长">
                <i-input size="large" v-model="counselorForm.beGoodAt" placeholder="以空格分隔"></i-input>
              </FormItem>
            </i-col>
          </Row>
        </i-col>
        <i-col span="6">
          <Upload type="drag" action=" ">
            <div class="photoUpload">
              <div>
                <Icon type="ios-cloud-upload"></Icon>
                <p>单击或托入此框上传照片</p>
              </div>
            </div>
          </Upload>
        </i-col>
        <i-col span="24">
          <FormItem label="介绍">
            <quill-editor ref="counselorIntro" :options="introOption" v-model="counselorForm.intro"></quill-editor>
          </FormItem>
          <Button size="large" type="primary" class="wideButton mb-2">提 交</Button>
        </i-col>
      </Row>
    </i-form>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import Quill from "quill"; //引入编辑器
export default {
  name: "CounselorDetails",
  components: {
    quillEditor,
  },
  data() {
    return {
      counselorForm: {
        counselorId: "",
        counselorName: "",
        gender: 1,
        birthday: "",
        bindPhone: "",
        price: 0,
        discount: 1,
        technicalTitle: 0,
        certificate: "",
        beGoodAt: "",
        way: [0],
        intro: "",
      },
      titleOption: ["二级咨询师", "三级咨询师", "心理医生"],
      wayOption: ["面询", "视频", "语音"],
      maxTags: 3,
      ruleVale: {},
      introOption: {
        modules: {
          toolbar: [
            //别改变size和font顺序
            { size: [] },
            { font: [] },
            { color: [] },
            { background: [] },
            "bold",
            "italic",
            "blockquote",
            { align: [] },
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
        },
        placeholder: "",
        theme: "snow",
      },
    };
  },
  created() {
    // 判断是编辑还是新建
    this._initialization();

    // 初始化文本编辑器
    this.myjs.setEditor(Quill, this.introOption);
  },
  mounted() {
    window.addEventListener(
      "resize",
      () => this.setTags(window.innerWidth),
      false
    );
  },
  methods: {
    // 设置面包屑
    crumbstring(str, len) {
      let setValue = {
        lenCtrl: len,
        addValue: str,
      };
      this.$store.dispatch("setBreadcrumb", setValue);
    },
    _initialization() {
      let a = JSON.parse(sessionStorage.getItem("pageStr"));
      // 判断编辑还是新建
      if (a.counselorId) {
        this.counselorForm = a;
        this.crumbstring(["从业者详情", a.counselorName], 2);
      } else {
        this.crumbstring(["添加从业者"], 2);
      }
    },
    setTags(width) {
      if (width >= 1680) {
        this.maxTags = 3;
      } else if (width >= 1440) {
        this.maxTags = 2;
      } else {
        this.maxTags = 1;
      }
    },
  },
};
</script>

<style lang="less" scoped="scoped">
@import "../assets/quill.css";
.photoUpload {
  padding: 1rem 0;
  height: 14.5rem;
  display: flex;
  display: -webkit-flex;
  /* Safari */
  justify-content: center;
  align-items: center;
}
/deep/.ql-editor {
  height: 24rem;
}
</style>
